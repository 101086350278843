import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames/bind';
const icon = require('../img/the-muffin-tops-icon.svg');

const styles = require('../css/footer.scss');
const cx = classNames.bind(styles);
const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const Footer = () => (
	<footer className={cx('o-footer')}>
		<div className="g-container">
			<div className="g-row">
				<div className="g-col">
					<ul className={cx('o-footer__list')}>
						<li className={cx('o-footer__item')}>
							<span className={cx('o-footer__text')}>
								© {currentYear} The Muffin Tops
								<span className={cx('o-footer__text o-footer__text--only-md')}>:</span>{' '}
								<span className={cx('o-footer__text o-footer__text--only-md')}>
									Finest Funk from Frankfurt
								</span>
							</span>
						</li>
						<li className={cx('o-footer__item')}>
							<Link
								to="/impressum"
								className={cx('o-footer__link')}
								activeClassName={cx('m-footer__link--active')}
							>
								Impressum
							</Link>
						</li>
						<li className={cx('o-footer__item')}>
							<Link
								to="/datenschutz"
								className={cx('o-footer__link')}
								activeClassName={cx('m-footer__link--active')}
							>
								Datenschutz
							</Link>
						</li>
					</ul>
				</div>
				<div className={cx('o-footer__icon-container')}>
					<img className={cx('o-footer__icon')} src={icon} alt="The Muffin Tops Icon" />
				</div>
			</div>
		</div>
	</footer>
);

export default Footer;
