import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import classNames from 'classnames/bind';
import Footer from '../../footer/js/footer';

// Load global assets
import '../../../assets/css/loader/loader.scss';

// Load dedicated styles for component
const styles = require('../css/layout.scss');
const cx = classNames.bind(styles);

const Layout = ({ children, location }) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
			}
		`}
		render={(data) => (
			<>
				<Helmet title={data.site.siteMetadata.title}>
					<html lang="de" />
					<script
						key="jsonld"
						async
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: `
							{
								"@context": "http://www.schema.org",
								"@type": "person",
								"name": "The Muffin Tops",
								"jobTitle": "Band",
								"url": "https://themuffintops.de",
								"image": "https://themuffintops.de/image.jpg",
								"description": {data.site.siteMetadata.description},
								"address": {
									"@type": "PostalAddress",
									"streetAddress": "Im Setzling 22a",
									"addressLocality": "Bad Nauheim",
									"addressRegion": "Hessen",
									"postalCode": "61231",
									"addressCountry": "Deutschland"
								}
							}
							`,
						}}
					/>
				</Helmet>
				{
					// Set correct modifier class depending on url
					location.pathname === '/' ? (
						<main className={cx('o-layout', 'o-layout--polygon-slider')}>{children}</main>
					) : (
						<main className={cx('o-layout')}>{children}</main>
					)
				}
				<Footer />
			</>
		)}
	/>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
