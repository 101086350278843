import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import Facebook from './facebook';

const SEO = ({ title = null, description = null, image = null, pathname = null, article = false }) => (
	<StaticQuery
		query={graphql`
			query SEOQuery {
				site {
					siteMetadata {
						defaultTitle: title
						titleTemplate
						defaultDescription: description
						siteUrl: url
						defaultImage: image
						facebookAppID
					}
				}
			}
		`}
		render={({
			site: {
				siteMetadata: { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage, facebookAppID },
			},
		}) => {
			const seo = {
				title: title || defaultTitle,
				description: description || defaultDescription,
				image: `${siteUrl}/${image || defaultImage}`,
				url: `${siteUrl}${pathname || '/'}`,
			};

			return (
				<>
					<Helmet title={seo.title} titleTemplate={titleTemplate}>
						<meta name="description" content={seo.description} />
						<meta name="image" content={seo.image} />
					</Helmet>
					<Facebook
						pageUrl={seo.url}
						type={article ? 'article' : null}
						title={seo.title}
						description={seo.description}
						image={seo.image}
						appID={facebookAppID}
					/>
				</>
			);
		}}
	/>
);

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string,
	pathname: PropTypes.string,
	article: PropTypes.bool,
};

export default SEO;
